"use client";

import { type ChangeEvent, type FormEvent, useState } from "react";

import { doCredentialLogin } from "@/app/actions/login";
import { Button } from "@/components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { passwordSchema, usernameSchema } from "@/lib/validationSchemas";

export default function LoginForm() {
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [pending, setPending] = useState(false);
  const [usernameError, setUsernameError] = useState<string>("");
  const [passwordError, setPasswordError] = useState<string>("");

  const validateUsername = (username: string) => {
    const result = usernameSchema.safeParse(username);
    if (!result.success) {
      setUsernameError(result.error.issues[0].message);
      return false;
    }
    setUsernameError("");
    return true;
  };

  const validatePassword = (password: string) => {
    const result = passwordSchema.safeParse(password);
    if (!result.success) {
      setPasswordError(result.error.issues[0].message);
      return false;
    }
    setPasswordError("");
    return true;
  };

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setPending(true);
    const formData = new FormData(event.currentTarget);
    const username = formData.get("username") as string;
    const password = formData.get("password") as string;

    if (!validateUsername(username) || !validatePassword(password)) {
      setPending(false);
      return;
    }

    try {
      const response = await doCredentialLogin(formData);

      if (!!response.error) {
        console.error(response.error);
        setErrorMessage(response.error.message);
      } else {
        // router.push("/cases");
        window.location.href = "/cases";
      }
    } catch (error) {
      console.error("Credential error", error);
      setErrorMessage("Login failed. Please try again.");
      setPending(false);
    }
  };

  return (
    <Card className="w-full max-w-sm">
      <CardHeader>
        <CardTitle className="text-2xl">Login</CardTitle>
        <CardDescription>
          Enter your username below to login to your account.
        </CardDescription>
      </CardHeader>
      <form onSubmit={handleSubmit}>
        <CardContent className="grid gap-4">
          <div className="grid gap-2">
            <Label htmlFor="username">Username</Label>
            <Input
              id="username"
              type="text"
              name="username"
              placeholder="username"
              required
              onChange={(event: ChangeEvent<HTMLInputElement>) =>
                validateUsername(event.target.value)
              }
            />
            {usernameError && <p className="text-red-500">{usernameError}</p>}
          </div>
          <div className="grid gap-2">
            <Label htmlFor="password">Password</Label>
            <Input
              id="password"
              type="password"
              name="password"
              required
              onChange={(event: ChangeEvent<HTMLInputElement>) =>
                validatePassword(event.target.value)
              }
            />
            {passwordError && <p className="text-red-500">{passwordError}</p>}
          </div>
          {errorMessage && <p className="text-red-500">{errorMessage}</p>}
        </CardContent>
        <CardFooter>
          <Button type="submit" className="w-full" disabled={pending}>
            {pending ? "Signing in..." : "Sign in"}
          </Button>
        </CardFooter>
      </form>
    </Card>
  );
}
