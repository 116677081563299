// validationSchemas.ts
import { z } from "zod";

export const usernameSchema = z
  .string()
  .email({ message: "Invalid email address" });

export const passwordSchema = z
  .string()
  .min(8, { message: "Must be 8 or more characters long" });
// .regex(new RegExp(".*[A-Z].*"), {
//   message: "Must contain one uppercase character",
// })
// .regex(new RegExp(".*\\d.*"), { message: "Must contain one number" });
// .regex(new RegExp(".*[`~<>?,./!@#$%^&*()\\-_+=\"'|{}\\[\\];:\\\\].*"), { message: "Must contain one special character" });
